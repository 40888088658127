import React from "react";
import { Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Progress,
  Countdown,
  StartingSoon,
  MatchingBar,
  ConfettiFall,
} from "..";
import { format, differenceInHours } from "date-fns";
import { campaignStatsStyles } from "./CampaignStats.styles";
import { getUrls, uiActions } from "../../state";
import {
  isMatchingCampaign,
  isProductCampaign,
  isRaffleCampaign,
} from "../../lib";

export function _CampaignStats(props) {
  const {
    forVolunteer,
    forTeam,
    campaign: { startingSoon, hide_stats_component, ended },
  } = props;
  const classes = campaignStatsStyles({ hide_stats_component });

  if (startingSoon) return <StartingSoon />;
  return (
    <div className={classes.wrapper}>
      <MatchingBar />

      {ended && <ConfettiFall />}

      {!hide_stats_component && (
        <Stats forVolunteer={forVolunteer} forTeam={forTeam} />
      )}

      {ended && (
        <div className={classes.endedTxt}>
          Thank you for making our campaign a success!
        </div>
      )}
    </div>
  );
}

export const CampaignStats = connect(state => {
  return {
    campaign: state.campaign,
  };
})(_CampaignStats);

function _Stats(props) {
  const {
    forVolunteer,
    forTeam,
    homeUrl,
    campaign: {
      campaign_type_id,
      hide_countdown,
      hide_campaign_stats_on_volunteer_pages,
      end_date,
      allow_donations,
      isActive,
      ended,
      informational_site_only,
    },
  } = props;
  const dispatch = useDispatch();
  const isProduct = isProductCampaign(campaign_type_id);
  const isMatch = isMatchingCampaign(campaign_type_id);
  const isRaffle = isRaffleCampaign(campaign_type_id);
  const EndsWithin2Weeks =
    end_date && differenceInHours(new Date(end_date), new Date()) <= 336; // 2 weeks 14 days * 24 hours
  const showCountdownClock = !isRaffle || EndsWithin2Weeks;
  const hideDonateBtn = isProduct && !allow_donations;
  const has2Buttons = Boolean(isProduct && !hideDonateBtn);
  const classes = campaignStatsStyles({
    showCountdownClock,
    hide_countdown,
    ended,
    isMatch,
    has2Buttons,
  });

  const LinkButtons = () => {
    return (
      <div className={classes.linkButtonWrapper}>
        {isProduct && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`${homeUrl}#products`}
            className={classes.linkButton}
            disabled={!isActive}
            onClick={() => dispatch(uiActions.updateHashLinkTrigger())}
          >
            Shop
          </Button>
        )}
        {!hideDonateBtn && (
          <Button
            variant="contained"
            color={isProduct ? "secondary" : "primary"}
            component={Link}
            to={`${homeUrl}#donate`}
            className={classes.linkButton}
            disabled={!isActive}
            onClick={() => dispatch(uiActions.updateHashLinkTrigger())}
          >
            Donate
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={classes.statsContainer}>
      <div className={classes.progressContainer}>
        <Progress forVolunteer={forVolunteer} forTeam={forTeam} />

        {/* org progress for team & volunteer pages */}
        {((forVolunteer && !hide_campaign_stats_on_volunteer_pages) ||
          forTeam) && (
          <div className={classes.progSpacer}>
            <Progress smallOrg={true} />
          </div>
        )}
      </div>

      {!ended && !hide_countdown && (
        <div className={classes.endDateContainer}>
          {showCountdownClock && <Countdown />}
          {!showCountdownClock && end_date && (
            <div className={classes.endsTxt}>
              Campaign ends {format(new Date(end_date), "LLLL d, y")}
            </div>
          )}
        </div>
      )}

      {!ended && !informational_site_only && <LinkButtons />}
    </div>
  );
}

const Stats = connect(state => {
  const { homeUrl } = getUrls(state);
  return {
    homeUrl,
    campaign: state.campaign,
  };
})(_Stats);
