import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import { formatMoney, hexToRGBA } from "../../lib";
import { SwitchDeliveryWarningDialog } from "./SwitchDeliveryWarningDialog";

function _MobileMiniOrderSummary(props) {
  const {
    emptyCart,
    orderItemsTotal,
    productDonation,
    isShipToSchool,
    orderItemCount,
    ship_to_school_label,
    hasMultiShipOpts,
  } = props;
  const classes = styles();
  const isLgScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const [showDeliverySwitch, setShowDeliverySwitch] = useState(false);

  if (isLgScreen || emptyCart) return <></>;
  return (
    <div className={classes.container}>
      <div className={classes.header}>Order summary</div>

      {orderItemsTotal > 0 && (
        <div className={classes.row}>
          <div className={classes.label}>Items subtotal</div>
          <div className={classes.amount}>{formatMoney(orderItemsTotal)}</div>
        </div>
      )}

      {productDonation > 0 && (
        <div className={classes.row}>
          <div className={classes.label}>Donation</div>
          <div className={classes.amount}>{formatMoney(productDonation)}</div>
        </div>
      )}

      {orderItemCount > 0 && (
        <div className={classes.row}>
          <div className={classes.label}>{`Shipping & fees`}</div>
          <div className={classes.calcTxt}>Calculated at next step</div>
        </div>
      )}

      {orderItemCount > 0 && (
        <Fragment>
          <div className={classes.shipType}>
            {isShipToSchool ? (
              <SchoolIcon className={classes.icon} />
            ) : (
              <HomeIcon className={classes.icon} />
            )}
            {isShipToSchool
              ? `Shipping to ${ship_to_school_label}`
              : "Shipping to home"}
          </div>
          {hasMultiShipOpts && (
            <div className={classes.changeDelivery}>
              <div>Change delivery to </div>
              <div
                className={classes.changeDeliveryLink}
                onClick={() => setShowDeliverySwitch(true)}
              >
                Ship to {isShipToSchool ? "home" : ship_to_school_label}
              </div>
            </div>
          )}
        </Fragment>
      )}

      {showDeliverySwitch && (
        <SwitchDeliveryWarningDialog
          closeDialog={() => setShowDeliverySwitch(false)}
        />
      )}
    </div>
  );
}

export const MobileMiniOrderSummary = connect(state => {
  const {
    product: {
      emptyCart,
      orderItemCount,
      orderItemsTotal,
      productDonation,
      isShipToSchool,
      shipping_options,
    },
    campaign: { ship_to_school_label },
  } = state;
  return {
    emptyCart,
    orderItemCount,
    orderItemsTotal,
    productDonation,
    isShipToSchool,
    ship_to_school_label,
    hasMultiShipOpts: shipping_options === "both",
  };
})(_MobileMiniOrderSummary);

const styles = makeStyles(theme => ({
  container: {
    width: 732,
    maxWidth: "100vw",
    padding: "0 16px",
  },
  header: {
    marginBottom: 24,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  label: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "17px",
  },
  amount: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.25,
    lineHeight: "22px",
  },
  shipType: {
    height: 24,
    width: "fit-content",
    padding: "0 8px",
    borderRadius: 14,
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.25),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    color: theme.palette.primary.main,
    marginBottom: 32,
  },
  icon: {
    marginRight: 4,
    fontSize: 16,
  },
  changeDelivery: {
    marginTop: -20,
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    color: "#AAAAAA",
    marginBottom: 32,
  },
  changeDeliveryLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    marginLeft: 4,
    cursor: "pointer",
  },
  calcTxt: {
    fontSize: 14,
    letterSpacing: 0.17,
  },
}));
